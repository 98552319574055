<template>
  <div class="text-box">
    <div class="label" v-for="(item,index) in list" :key="index">
      <i>{{item.id}} /</i>
      {{item.label}}
      <img v-if="item.image" src="@/assets/images/statement/cur.png" alt />
      <span v-if="item.image">{{item.label2}}</span>
      <ul v-if="item.children">
        <li v-for="(o,oIndex) in item.children" :key="oIndex">
          <span></span>
          {{o}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.list = [
        {
          id: "01",
          label:
            "我们承诺服务到位满意为止，如有任何问题请您及时联系我们，我们一定全力以赴。本站一经充值恕不退款，望见谅！"
        },
        {
          id: "02",
          label: "如果以下情况，我们将不退还钱款：",
          children: [
            "打开两个页面同时下载两张或两张以上相同的文件。",
            "非正常操作，双击或多次点击确认下载按钮",
            "您的浏览器奔溃/错误，程序错误等等",
            "由作者上传文件或第三方服务器崩溃的错误。",
            "错误的互联网连接问题"
          ]
        },
        {
          id: "03",
          label:
            "如第三方网站维护/无法正常访问/关闭等，我们将及时发布公告进行通知！"
        },
        {
          id: "04",
          label:
            "本站代下所有文件，只提供个人编辑使用不可以商用，引起版权纠纷由您自行承担，如需商用请前往原网站购买。"
        },
        {
          id: "05",
          label: "三天内您可以免费重新下载“下载历史”的每个图像！"
        },
        {
          id: "06",
          label:
            "我们保证代下载的默认矢量，如没有矢量则为最高尺寸JPG。文件格式：EPS / JPG / ZIP"
        },
        {
          id: "07",
          label: "223开头的10位数ID因为版权问题，普通自动下载方式无法下载，可以提供代购服务，如有需要请联系网站客服。"
        },
        {
          id: "08",
          label: "如果您看到的图标网站为",
          image: true,
          label2:
            "【更新状态】，通常需要15分钟至8小时（非工作日可能会更久） 此时您可以选择其他网站下载。"
        },
        {
          id: "09",
          label: "如果您对于购买其他视频，音乐，3D文件或其他网站素材有需求，请通过微信客服与我们联系以获取报价!"
        },
        {
          id: "10",
          label: "如果下载的文件是缓慢或不稳定的连接，请检查您的网络连接，建议重启路由器后再试！"
        },
        {
          id: "11",
          label: "当您【注册】/【登录】并开始使用本站，您的使用行为将被视为对本声明全部内容的认可。"
        },
        {
          id: "12",
          label: "如果您不理解这些规则，您必须立即离开并关闭本网站！"
        },
        {
          id: "13",
          label: "如果用户违反使用声明，您将永远禁止帐户！"
        },
        {
          id: "14",
          label: "我们保留随时更改任何条款，规则，条件或本网站的政策，在我们的任何时间自行决定的权利。"
        },
        {
          id: "15",
          label: "敲黑板，重点已经帮同学们划出来了！！"
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.text-box {
  i {
    position: absolute;
    left: 0;
    top: 0;
  }
  .label {
    line-height: 40px;
    color: #1f1f1f;
    font-size: 14px;
    padding-left: 34px;
    position: relative;
  }
  ul {
    padding: 10px 0 20px 0;
    li {
      line-height: 30px;
      color: #545454;
      position: relative;
      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 1px;
        background: #000;
      }
    }
  }
}
</style>
