<template>
  <div class="text-box">
    <p v-for="(item,index) in list" :key="index">
      <i>{{item.id}} /</i>
      {{item.label}}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.list = [
        {
          id: "01",
          label:
            "均来自网友的上传。系统站内所有作品均由网友上传而来，由网友自己管理与负责，系统不拥有此类作品的版权。标明来自合作网站的作品，均由合作网站自动提供并在系统仅作展示，系统无法事先审查此类作品及作品信息的版权等问题，但会积极协助用户解决可能遇到的问题。"
        },
        {
          id: "02",
          label:
            "仅限用于学习交流。系统作品均由系统用户上传用于学习交流之用，勿作它用；若需商业使用，需获得版权拥有者授权，并遵循国家相关法律、法规之规定。如因非法使用引起纠纷，一切后果由使用者承担。标明来自合作网站的作品，可在原网站付费下载后，根据约定使用。"
        },
        {
          id: "03",
          label:
            "初步审查上传作品。系统对用户上传的作品及其他信息，将根据法律法规、规章制度、相关政策及系统的规定进行审查。对经初步审查合格的作品，将予以审查通过，允许在系统上展示分享。但作品审查通过，不代表系统对作品不侵权的任何保证，也不代表用户对其上传的作品应负的责任减轻、免除或转嫁到系统，作品仍由用户自行负责。"
        },
        {
          id: "04",
          label:
            "及时清理转载盗版。系统作为作品共享网络服务提供者，对非法转载、盗版行为的发生不具备监控能力。但是当版权拥有者提出侵权指控并出示充分的版权证明材料时，系统将会立即移除盗版和非法转载作品。系统对他人在网站上实施的此类侵权行为不承担法律责任，侵权的法律责任概由上传者本人承担。系统也不承担因采取移除等相应措施带来的任何责任，包括因侵权指控不成立而给作品上传者带来损害的赔偿责任。"
        },
        {
          id: "05",
          label:
            "标注原创的说明。作品资料中标注原创与不标注原创的区别在于，上传用户对作品类型的选择不同。标注原创，不代表系统保证此类作品一定为用户原创，也不代表系统对此类作品的审查责任因此有所增加；而不标注原创，也不代表系统否认此类作品为用户原创。"
        },
        {
          id: "06",
          label:
            "版权投诉的途径。如果版权拥有者发现自己作品被侵权，请向系统提出权利通知，并将身份证明、权属证明、具体链接（URL）及详细侵权情况证明发往137120847@qq.com。系统在收到上述法律文件后，在五个工作日内移除相关涉嫌侵权的内容。投诉来自合作网站的作品的，请向合作网站进行投诉，并及时通知系统屏蔽相关作品展示信息。"
        },
        {
          id: "07",
          label: "系统素材仅限中国大陆下载。"
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.text-box {
  i {
    position: absolute;
    left: 0;
    top: 0;
  }
  p {
    line-height: 40px;
    color: #1f1f1f;
    font-size: 14px;
    padding-left: 34px;
    position: relative;
  }
}
</style>