<template>
  <div>
    <el-tabs v-model="currentTabIndex" class="tabs-list">
<!--      <el-tab-pane label="版权声明" name="first" class="tabs-content">-->
<!--        <copyright></copyright>-->
<!--      </el-tab-pane>-->
      <el-tab-pane label="代下声明" name="second" class="tabs-content">
        <download></download>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import copyright from "./module/copyright.vue";
import download from "./module/download.vue";
export default {
  components: {
    copyright,
    download
  },
  data() {
    return {
      currentTabIndex: "second"
    };
  },
  methods: {}
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.tabs-content {
  border-radius: 4px;
  padding: 0 40px;
  margin-bottom: 10px;
  padding: 30px 40px;
}
</style>